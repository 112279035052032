import React from 'react'
import Navbar from "./global-components/navbar-v3";
import BannerV5 from "./section-components/banner-v5";
import Footer from "./global-components/footer";
import { Link } from "react-router-dom";

const Geography = () => {
  return (
    <>
            <Navbar CustomClass="ltn__header-transparent gradient-color-2" />
            <BannerV5
                headerTitle={"Geography assignment writing"}
                typeWriterValue={[
                    "Put a stop to deadline pressure, and have your homework done  ",
                    "by an expert.",
                    // "thesis",
                    // "PROJECT REPORT",
                ]}
                smallText={
                    ""
                }
            />
            <div className="container mt-100 mb-100">
                <div className="row ltn__custom-gutter--- justify-content-center go-top mb-50">
                    <div className="col-lg-3 col-sm-6 col-12" >
                        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                            <div className="ltn__feature-icon">
                                <img
                                    src={"assets/img/icons/icon-img/22.png"}
                                    alt="#"
                                />
                            </div>
                            <div className="ltn__feature-info">
                                <h3>
                                    <Link to="#">182 300+</Link>
                                </h3>
                                <h6>
                                    <Link to="#">DELIVERED ORDERS</Link>
                                </h6>
                                {/* <p>{data.discription}</p>
<Link className="ltn__service-btn" to="/contact">
Order now <i className="flaticon-right-arrow" />
</Link> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12" >
                        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                            <div className="ltn__feature-icon">
                                <img
                                    src={"assets/img/icons/icon-img/22.png"}
                                    alt="#"
                                />
                            </div>
                            <div className="ltn__feature-info">
                                <h3>
                                    <Link to="#">5844 +</Link>
                                </h3>
                                <h6>
                                    <Link to="#">EXPERTS</Link>
                                </h6>
                                {/* <p>{data.discription}</p>
<Link className="ltn__service-btn" to="/contact">
Order now <i className="flaticon-right-arrow" />
</Link> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12" >
                        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                            <div className="ltn__feature-icon">
                                <img
                                    src={"assets/img/icons/icon-img/22.png"}
                                    alt="#"
                                />
                            </div>
                            <div className="ltn__feature-info">
                                <h3>
                                    <Link to="#">4.9/5</Link>
                                </h3>
                                <h6>
                                    <Link to="#">CLIENT RATING</Link>
                                </h6>
                                {/* <p>{data.discription}</p>
<Link className="ltn__service-btn" to="/contact">
Order now <i className="flaticon-right-arrow" />
</Link> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12" >
                        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                            <div className="ltn__feature-icon">
                                <img
                                    src={"assets/img/icons/icon-img/22.png"}
                                    alt="#"
                                />
                            </div>
                            <div className="ltn__feature-info">
                                <h3>
                                    <Link to="#">5+ Countries</Link>
                                </h3>
                                <h6>
                                    <Link to="#">Operated in 5+ Countries</Link>
                                </h6>
                                {/* <p>{data.discription}</p>
<Link className="ltn__service-btn" to="/contact">
Order now <i className="flaticon-right-arrow" />
</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
                <h4>Which Is The Best Type Of Geography assignment writing For You?</h4>
                <p>Homework Help provides all kinds of help with geographic homework, whether it's a small homework thesis or a long-term project. It doesn't matter to us when the deadline is approaching or how often you want us to help you because we have a great team of professionals who are used to working to high standards even under pressure. You can purchase whatever 4,444 geography homework help you need.</p>
                <h4>Are You Looking For Geography Homework Ideas?</h4>
                <p>It is possible that you are just starting your course and are not really sure what type of job you should apply for. Or maybe your teacher recently criticized of your geography homework answers and you want to impress them. If that's the case , we can help you by doing the following task (or upcoming missions) at just to give you an idea of ​​what you should be aiming for when testing Geography volume for high school. If you don't know how to do something, you better call for help at .</p>
                <h4>Need Support With Your Geography Homework thesis?</h4>
                <p>Sometimes it seems like a school year is going well and is going pretty well - and then you get a project that's a little too much for you. Homework Help can take on this kind of custom work because we have a large group of people with years of experience helping with geography homework. Our affordable service means we can take care of it for you and help you manage your workload - or just gets a high score you can be proud of..</p>
                <h4>Do You Want Some More Regular Support?</h4>
                <p>In addition to major projects, we also advise people when they need someone to do my geography homework on a more regular basis. We understand that school isn't easy, so this purchase could be the right choice for you if any of the following are true:</p>
               <ul>
                <li>Not enough time to complete all schoolwork;</li>
                <li>You need to prioritize another subject;</li>
                <li>You want to get some good marks to balance out some lower ones;</li>
                <li>You’re feeling a bit overwhelmed and need a bit more free time;</li>
                <li>You have an important match or event coming up that you need to prepare for.</li>
               </ul>
               
            </div>

            {/* <CallToActionV1 /> */}
            <Footer />
        </>
  )
}

export default Geography
