import React from 'react'
import Navbar from "./global-components/navbar-v3";
import BannerV5 from "./section-components/banner-v5";
import Footer from "./global-components/footer";
import { Link } from "react-router-dom";

const Math = () => {
    return (
        <>
            <Navbar CustomClass="ltn__header-transparent gradient-color-2" />
            <BannerV5
                headerTitle={"Math assignment writing"}
                typeWriterValue={[
                    "Put a stop to deadline pressure, and have your homework done  ",
                    "by an expert.",
                    // "thesis",
                    // "PROJECT REPORT",
                ]}
                smallText={
                    ""
                }
            />
            <div className="container mt-100 mb-100">
                <div className="row ltn__custom-gutter--- justify-content-center go-top mb-50">
                    <div className="col-lg-3 col-sm-6 col-12" >
                        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                            <div className="ltn__feature-icon">
                                <img
                                    src={"assets/img/icons/icon-img/22.png"}
                                    alt="#"
                                />
                            </div>
                            <div className="ltn__feature-info">
                                <h3>
                                    <Link to="#">182 300+</Link>
                                </h3>
                                <h6>
                                    <Link to="#">DELIVERED ORDERS</Link>
                                </h6>
                                {/* <p>{data.discription}</p>
<Link className="ltn__service-btn" to="/contact">
Order now <i className="flaticon-right-arrow" />
</Link> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12" >
                        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                            <div className="ltn__feature-icon">
                                <img
                                    src={"assets/img/icons/icon-img/22.png"}
                                    alt="#"
                                />
                            </div>
                            <div className="ltn__feature-info">
                                <h3>
                                    <Link to="#">5844 +</Link>
                                </h3>
                                <h6>
                                    <Link to="#">EXPERTS</Link>
                                </h6>
                                {/* <p>{data.discription}</p>
<Link className="ltn__service-btn" to="/contact">
Order now <i className="flaticon-right-arrow" />
</Link> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12" >
                        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                            <div className="ltn__feature-icon">
                                <img
                                    src={"assets/img/icons/icon-img/22.png"}
                                    alt="#"
                                />
                            </div>
                            <div className="ltn__feature-info">
                                <h3>
                                    <Link to="#">4.9/5</Link>
                                </h3>
                                <h6>
                                    <Link to="#">CLIENT RATING</Link>
                                </h6>
                                {/* <p>{data.discription}</p>
<Link className="ltn__service-btn" to="/contact">
Order now <i className="flaticon-right-arrow" />
</Link> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12" >
                        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                            <div className="ltn__feature-icon">
                                <img
                                    src={"assets/img/icons/icon-img/22.png"}
                                    alt="#"
                                />
                            </div>
                            <div className="ltn__feature-info">
                                <h3>
                                    <Link to="#">5+ Countries</Link>
                                </h3>
                                <h6>
                                    <Link to="#">Operated in 5+ Countries</Link>
                                </h6>
                                {/* <p>{data.discription}</p>
<Link className="ltn__service-btn" to="/contact">
Order now <i className="flaticon-right-arrow" />
</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
                <h4>Professional Math Homework Help</h4>
                <p>Do you ever ask yourself “Who can I trust to solve my math thesis?” or “Who is willing to even do my math thesis?” Believe it or not, you aren’t alone in contemplating these questions. There are thousands of students in schools across the world that struggle with math courses and desperately try to find reliable mathematical homework help. Let’s face it: Math is hard! Besides having to learn all of the concepts, theories and having to put in quite a bit of critical thinking, the ever-increasing problem of class sizes leads to instructors being unable to give one-on-one assistance to learners. As a result, more students nowadays are likely to struggle with the subject than those who attended classes merely a decade ago. And while reforming the educational system is out of our hands, we at assignment writing can certainly help with math homework in ways that other homework assistance companies can’t.</p>
           
                <p>You’re probably wondering, “So how can assignment writing solve my math thesis?” The answer is: We hire only math professionals to provide you with the mathematical homework help you need to get back on track and earn a spot at the top of the class. Our experts have been studying upper-level math for years and can provide you with the most accurate solutions to small and large problem sets, written responses and so much more.</p>
                <p className='text-center'>You can get help with your Algebra or Geometry thesis.</p>
                <h4>Solving Your Math thesis</h4>
                <p>Our courteous customer support team is available to respond to your questions 24/7. So if you need help with math late into the evening, the thesis writing will have someone to assist you. For long written pieces – such as math essays or term papers – you will receive a list of qualified math writers from which you can choose the expert you want to hire. This is instrumental in building a positive learning experience since the writer you choose will communicate concepts in your paper that you will need to learn in preparation for term-end or year-end tests.</p>
                <p>Indeed, assignment writing is among the best math homework services around because we treat every student individually and cater our services for each case. Simply give us a call or send us an email with the subject line reading “Do my math thesis,” and we’ll get the ball rolling. We’ll complete your thesis quickly and efficiently, providing all the necessary explanations so that you can either copy the work by hand to submit as your own or review its content and use a study guide for solving the math problems on your own.</p>
                <p>Here at assignment writing we aim to get things done right the first time. We’re so confident in our expertise that we offer free revisions on any project you aren’t completely satisfied p1 with. Just give us a try and we’re sure you won’t seek another homework help service. Don’t wait till the end of the year when you are overwhelmed with tests and other thesis. Get ahead of the curve by placing your order today and find out firsthand why hundreds of students enlist our help daily. When it comes to high-quality, affordable homework help, there is no better option!</p>
                <h4>How Much Time Does It Take to Do My Math Homework Online?</h4>
                <p>assignment writing  doesn’t only offer high-quality and reliable math help services, but also a fast service for those students who are in a hurry. It doesn’t matter if you need to solve algebra or geometry problems, or if you need to write a paper regarding a math topic, if you contact us, no matter the hour, expect to get the best professional help from experts who know exactly how to solve your problem. Our writers are qualified to offer you the best math help because they graduated in mathematics, and they will use their knowledge to handle all the theories and equations in the way required. Finding good math homework help online is not a big deal, but no service would be as good as ours. The homework or papers you need to hand in can be done in just a few hours. All you need to do is send the instructions and set a deadline, and the rest will be handled. If you’re in a hurry, simply contact us – ask for experts who will do your bidding and then relax while they carry out their responsibilities. You will have a better and more relaxed life once you’ve enlisted our services. We promise you that.</p>

            </div>

            {/* <CallToActionV1 /> */}
            <Footer />
        </>
    )
}

export default Math
