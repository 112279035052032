import React from 'react'
import Navbar from "./global-components/navbar-v3";
import BannerV5 from "./section-components/banner-v5";
import Footer from "./global-components/footer";
import { Link } from "react-router-dom";

const Economic = () => {
  return (
    <>
            <Navbar CustomClass="ltn__header-transparent gradient-color-2" />
            <BannerV5
                headerTitle={"Economic assignment writing"}
                typeWriterValue={[
                    "Put a stop to deadline pressure, and have your homework done  ",
                    "by an expert.",
                    // "thesis",
                    // "PROJECT REPORT",
                ]}
                smallText={
                    ""
                }
            />
            <div className="container mt-100 mb-100">
                <div className="row ltn__custom-gutter--- justify-content-center go-top mb-50">
                    <div className="col-lg-3 col-sm-6 col-12" >
                        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                            <div className="ltn__feature-icon">
                                <img
                                    src={"assets/img/icons/icon-img/22.png"}
                                    alt="#"
                                />
                            </div>
                            <div className="ltn__feature-info">
                                <h3>
                                    <Link to="#">182 300+</Link>
                                </h3>
                                <h6>
                                    <Link to="#">DELIVERED ORDERS</Link>
                                </h6>
                                {/* <p>{data.discription}</p>
<Link className="ltn__service-btn" to="/contact">
Order now <i className="flaticon-right-arrow" />
</Link> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12" >
                        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                            <div className="ltn__feature-icon">
                                <img
                                    src={"assets/img/icons/icon-img/22.png"}
                                    alt="#"
                                />
                            </div>
                            <div className="ltn__feature-info">
                                <h3>
                                    <Link to="#">5844 +</Link>
                                </h3>
                                <h6>
                                    <Link to="#">EXPERTS</Link>
                                </h6>
                                {/* <p>{data.discription}</p>
<Link className="ltn__service-btn" to="/contact">
Order now <i className="flaticon-right-arrow" />
</Link> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12" >
                        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                            <div className="ltn__feature-icon">
                                <img
                                    src={"assets/img/icons/icon-img/22.png"}
                                    alt="#"
                                />
                            </div>
                            <div className="ltn__feature-info">
                                <h3>
                                    <Link to="#">4.9/5</Link>
                                </h3>
                                <h6>
                                    <Link to="#">CLIENT RATING</Link>
                                </h6>
                                {/* <p>{data.discription}</p>
<Link className="ltn__service-btn" to="/contact">
Order now <i className="flaticon-right-arrow" />
</Link> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12" >
                        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                            <div className="ltn__feature-icon">
                                <img
                                    src={"assets/img/icons/icon-img/22.png"}
                                    alt="#"
                                />
                            </div>
                            <div className="ltn__feature-info">
                                <h3>
                                    <Link to="#">5+ Countries</Link>
                                </h3>
                                <h6>
                                    <Link to="#">Operated in 5+ Countries</Link>
                                </h6>
                                {/* <p>{data.discription}</p>
<Link className="ltn__service-btn" to="/contact">
Order now <i className="flaticon-right-arrow" />
</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
                <h4>Economics assignment writing Online</h4>
                <p>Most students are consistently bombarded by paper after paper, each with its own research requirements and writing style. With so many thesis it can be difficult for students to keep up and it becomes necessary to get some outside help. Economics students, especially those in upper division or graduate courses, will be faced with a conundrum when it comes to finding economics assignment writing . They can either pay a writing service for assistance or they can risk falling behind in class.</p>
                <h4>Where can I find someone reliable to do my economics homework?</h4>
                <p>This is a question we hear all the time. And we’re happy to say that the answer is actually quite simple. assignment writing is the #1 solution for your economics assignment writing. We provide imaginative and unique economics papers that you can submit as your own. Our writing, editing and proofreading services cater to a large client base at different academic levels throughout the world. We fully understand that the various pressures faced by students today can be quite overwhelming.</p>
                <p>Here’s where assignment writing can help with economy homework. We can write your economics term papers, research papers, and research reports for you for one low price. No matter what your reason for hiring us, doing so will save you valuable time and stress. Unlike some of our competitors who will write economics papers without making any communication or providing any support, assignment writing prides itself on customer satisfaction through all our services.</p>
                <h4>Top Quality From Best Economics Experts</h4>
                <p>When you contact assignment writing you will have the opportunity to choose which writer you want to work with from a long list of capable and knowledgeable writers with years of experience learning and writing about economics. You’ll find that our economics homework helpers are reliable and easy to consult. They will work with you throughout the process of completing your thesis, answering any questions as they come up and even providing you with updates if you request them.</p>
              <p>None of our economics homework helpers churn out paper after paper with only slight changes from previous copies. Each thesis is treated uniquely, fully researched and written completely from scratch. Unlike some of our competitors who hire non-native speakers, we hire only the best native English writers with years of experience working in economics. Get the help with economy homework you need to stay ahead of the class and earn the highest grades possible.</p>
              <p>Our writing professionals and customer support are available 24/7, so you will be able to place an order and get homework help anytime of the day. If you aren’t completely satisfied with our product we will provide revisions at no cost. Just be sure to order your paper early so that we have enough time to give you as many revisions as you need to get your paper just the way you want it. You aren’t going to find another writing company who can deliver on this same promise.</p>
                <h4>Our Best Economic thesis Writers Are Here To Save The Day</h4>
                <p>If you still have doubts on whether you can count on our team of writers, here are a few quick facts to convince you:</p>
                <ul>
                    <li>We have a bunch of qualified micro and macroeconomics specialists with profound knowledge in both these subjects. They keep abreast of the latest trends in economics and will surely provide you with an economics thesis, based on the most relevant data.</li>
                    <li>Needless to say, all our economics thesis writers are native English speakers, able to fetch you a brilliant economic solution in the shortest period of time.
So, you can stop posing the question: Who will do my economics thesis? Don’t hesitate! Give us a call today to find out more about our full range of services.</li>
                </ul>
               
            </div>

            {/* <CallToActionV1 /> */}
            <Footer />
        </>
  )
}

export default Economic
